import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import avatar from "../../asset/new/images/avatar-1577909_1280.png";
import { useParams } from "react-router-dom";
import { GetUser } from "../../Redux/Slices/User";
import AdminNavbar from "./Common/AdminNavbar";
import { CandidateStatus } from "../../Redux/Slices/JobPost";

const ApplicantProfile = () => {
  const { userData } = useSelector((state) => state?.User);
  const dispatch = useDispatch();
  const { id, jobid } = useParams();
  const role = localStorage.getItem("role");
  const [action, setAction] = useState("");

  // Url Path
  let resumeUrl = process.env.REACT_APP_AZURE_STORAGE_URL;
  // For Download PDf
  const handleDownload = async () => {
    let url = resumeUrl + userData?.resume;
    window.open(url, "_blank");
    handleapplicantStatus("Downloaded");
  };

  //--------- End of Resume -------

  // Industr Name
  let industryName = userData?.career?.industry
    .map((value) => value.name)
    .join("");

  // IT Name
  let itName = userData?.career?.skills
    .filter((value) => value.type === "IT")
    .map((value) => value.name)
    .join(", ");

  // FN Name
  let fnName = userData?.career?.skills
    .filter((value) => value.type === "Functional")
    .map((value) => value.name)
    .join(", ");

  //Location
  let location = userData?.career?.location?.map((value) => value)?.join(", ");

  useEffect(() => {
    dispatch(GetUser(id));
    document.title = "Applicant Profile";
  }, [dispatch]);

  const handleUpdate = (data) => {
    handleapplicantStatus(action);
  };

  const handleapplicantStatus = async (action) => {
    let data = {
      user_id:id,
      job_id:jobid,
      action: action,
    };
    await CandidateStatus(data);
  };

  return (
    <div className="main-panel">
      {role === "Admin" && <AdminNavbar />}
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 col-sm-4  ">
            <div className=" my-5 mt-3 text-center">
              <img
                alt="profile"
                src={userData?.profile_img || avatar}
                className="profile-img img-thumbnail rounded-circle"
              />
              <br />
              <select
                className="form-select"
                action_status="change_status"
                id="change_status"
                onChange={(e) => setAction(e.target.value)}
              >
                <option value="0"> Update Applicant Status</option>
                <option value="Viewed">Viewed</option>
                <option value="Downloaded"> Downloaded</option>
                <option value="Shortlisted">Shortlisted</option>
                <option value="Not Suitable">Not Suitable</option>
              </select>
              <br />
              <button
                type="button"
                id="update_status"
                className=" w-75 m-auto mt-2 "
                onClick={handleUpdate}
              >
                Update
              </button>
            </div>
          </div>
          <div className="col-12 col-sm-8">
            <div className="row">
              <div className="col">
                <label>
                  {userData?.resume ? (
                    <button
                      onClick={handleDownload}
                      type="button"
                      id="download1"
                      className="btn btn-info"
                    >
                      Download CV
                    </button>
                  ) : (
                    <button
                      type="button"
                      id="download1"
                      className="btn btn-info"
                    >
                      No Resume Available
                    </button>
                  )}
                </label>
              </div>
            </div>
            {/* Profile Details */}
            <div className="">
              {/*------ Personal Info ------*/}

              <div className="prfile-card my-4">
                <div className="d-flex justify-content-between">
                  <div className="d-flex justify-content-center">
                    <h3 className="card-head-line">Personal Info</h3>
                  </div>
                </div>
                {/*------ Profile Details ------  */}
                <div className="mt-2">
                  <p>
                    {" "}
                    <span className="prof-details">Name</span>:{" "}
                    {userData?.name || "Not Available"}
                  </p>
                  <p>
                    <span className="prof-details">Email</span> :{" "}
                    {userData?.email || "Not Available"}
                  </p>
                  <p>
                    <span className="prof-details">Phone</span> :{" "}
                    {userData?.mobile || "Not Available"}
                  </p>
                  <p>
                    <span className="prof-details">Experience</span> :{" "}
                    {userData?.experience_level || "Not Available"}
                  </p>
                </div>
              </div>

              {/* ------ Experience ----- */}
              <div className="prfile-card my-3">
                <div className="d-flex justify-content-between">
                  <div className="d-flex justify-content-center">
                    <h3 className="card-head-line">Experience Details</h3>
                  </div>
                </div>
                {/*------ Additional Details ---- */}

                {userData?.experience?.map((value, index) => (
                  <div className=" mt-2 border-bottom" key={`${index}`}>
                    <p>
                      <span className="prof-details">Designation</span>:{" "}
                      {value?.designation}{" "}
                    </p>
                    <p>
                      <span className="prof-details">Company</span> :{" "}
                      {value?.company?.name}
                    </p>
                    <p>
                      <span className="prof-details">Duration</span> :{" "}
                      {value?.duration?.from} To{" "}
                      {value?.duration?.to}
                    </p>
                    <p>
                      <span className="prof-details">Profile Description</span>{" "}
                      : {value?.profile_description}
                    </p>
                  </div>
                ))}
              </div>

              {/*------ Additional Details ------  */}
              <div className="prfile-card my-3">
                <div className="d-flex justify-content-between">
                  <div className="d-flex justify-content-center">
                    <h3 className="card-head-line">
                      Professional Qualification
                    </h3>
                  </div>
                </div>
                {/*------ Additional Details ---- */}

                {userData?.prof_qualification?.map((value, index) => (
                  <div className=" mt-2 border-bottom" key={`${index}`}>
                    <p>
                      <span className="prof-details">Degree</span>:{" "}
                      {value?.degree?.name}{" "}
                    </p>
                    <p>
                      <span className="prof-details">Term of Passing</span> :{" "}
                      {value?.term_of_passing?.year}{" "}
                      {value?.term_of_passing?.month}
                    </p>
                    <p>
                      <span className="prof-details">Grade Score</span> :{" "}
                      {value?.marks}
                    </p>
                    <p>
                      <span className="prof-details">No.of Attempts</span> :{" "}
                      {value?.attempts || "Not Available"}
                    </p>
                  </div>
                ))}
              </div>

              {/*------ Academic Details ------  */}
              <div className="prfile-card my-3">
                <div className="d-flex justify-content-between">
                  <div className="d-flex justify-content-center">
                    <h3 className="card-head-line">Academic Details</h3>
                  </div>
                </div>
                {/*------ Additional Details ---- */}

                {userData?.academic?.map((value, index) => (
                  <div className=" mt-2 border-bottom" key={`${index}`}>
                    <p>
                      <span className="prof-details">Education</span>:{" "}
                      {value?.graduation}{" "}
                    </p>
                    <p>
                      <span className="prof-details">Institution</span> :{" "}
                      {value?.college}
                    </p>
                    <p>
                      <span className="prof-details">Grade Score</span> :{" "}
                      {value?.marks}
                    </p>
                    <p>
                      <span className="prof-details">Year of Passing</span> :{" "}
                      {value?.year_of_passing}
                    </p>
                  </div>
                ))}
              </div>

              {/*------ Additional Details ------  */}
              <div className="prfile-card my-3">
                <div className="d-flex justify-content-between">
                  <div className="d-flex justify-content-center">
                    <h3 className="card-head-line">Additional Details</h3>
                  </div>
                </div>
                {/*------ Additional Details ---- */}

                <div className=" mt-2">
                  <p>
                    <span className="prof-details">Date of Birth</span>:{" "}
                    {userData?.dob}{" "}
                  </p>
                  <p>
                    <span className="prof-details">Gender</span> :{" "}
                    {userData?.gender}
                  </p>
                  <p>
                    <span className="prof-details">Marital Status</span> :{" "}
                    {userData?.marital_status}
                  </p>
                  <p>
                    <span className="prof-details">Address</span> :{" "}
                    {userData?.address?.address1}
                  </p>
                  <p>
                    <span className="prof-details">Pincode</span> :{" "}
                    {userData?.address?.pincode}
                  </p>
                </div>
              </div>

              {/*------ Career Profile ------  */}
              <div className="prfile-card my-3">
                <div className="d-flex justify-content-between">
                  <div className="d-flex justify-content-center">
                    <h3 className="card-head-line">Career Profile</h3>
                  </div>
                </div>
                {/*------ Additional Details ---- */}
                {userData?.career && (
                  <div className=" mt-2">
                    <p>
                      <span className="prof-details">Profile Headline</span>:{" "}
                      {userData?.career?.profile_headline}{" "}
                      {/* <MdDeleteOutline onClick={() => handleDelete(index)} /> */}
                    </p>
                    <p>
                      <span className="prof-details">
                        Career Accomplishments
                      </span>{" "}
                      : {userData?.career?.accomplishments}
                    </p>
                    <p>
                      <span className="prof-details">Expected Salary</span> :{" "}
                      {userData?.career?.expected_salary?.lac}Lacs{" "}
                      {userData?.career?.expected_salary?.thousand}
                    </p>
                    <p>
                      <span className="prof-details">Industry</span> :{" "}
                      {industryName}
                    </p>
                    <p>
                      <span className="prof-details">Functional Skills</span> :{" "}
                      {fnName}
                    </p>
                    <p>
                      <span className="prof-details">IT Skills</span> : {itName}
                    </p>
                    <p>
                      <span className="prof-details">Preferred Location</span> :{" "}
                      {location}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicantProfile;
