import React, { useEffect, useState } from "react";
import AdminNavbar from "./Common/AdminNavbar";
import AdminSideBar from "./Common/AdminSideBar";
import { useDispatch, useSelector } from "react-redux";
import { JobApproval, getAllJobs } from "../../Redux/Slices/JobPost";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../Helper/FromatTime";

const ApproveJobs = () => {
  const { allJobs } = useSelector((state) => state?.Jobs);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  // State to handle current page
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    let data = {
      admin_approval: "pending",
      page: 1,
      search: e.target.value,
    };
    dispatch(getAllJobs(data));
  };

  const statusHandler = async (id, data) => {
    const userClickedOK = window.confirm("Are you sure you want to proceed?");
    if (userClickedOK) {
      const action = { action: data };
      // API call
      await JobApproval(id, action);
      // Refresh the job list
      const Userdata = {
        admin_approval: "pending",
        page: currentPage,
      };
      dispatch(getAllJobs(Userdata));
    }
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
    const data = {
      admin_approval: "pending",
      page,
      search: searchQuery,
    };
    dispatch(getAllJobs(data));
  };

  useEffect(() => {
    const data = {
      admin_approval: "pending",
      page: currentPage,
    };
    dispatch(getAllJobs(data));
  }, [currentPage, dispatch]);

  return (
    <div>
      {/* ------- Navbar ------- */}
      <AdminNavbar />
      <div className="row m-0">
        <div className="col-12 col-md-2 p-0 nav-content">
          {/* ------- Sidebar ------- */}
          <AdminSideBar />
        </div>
        <div className="main-content col-md-10 col-12 p-0">
          {/* ------- Main Content  ------- */}
          <h2 className="headline mb-0">Approve Jobs</h2>
          <div className="main-content-child">
            <div className="d-flex justify-content-between">
              {/* Search Button */}
              <input
                type="search"
                id="form1"
                placeholder="Search"
                autoComplete="off"
                className="search-list m-0 mb-3"
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="col-md-12 detail-page_margin">
                  <div>
                    {/* Table */}
                    <table
                      id="example"
                      className="table table-striped table-bordered datatable responsive"
                      cellSpacing={0}
                      style={{ width: "100%", fontSize: "12px" }}
                      aria-describedby="example_info"
                    >
                      <thead>
                        <tr>
                          <th className="text-nowrap">Job ID</th>
                          <th>Job Title</th>
                          <th>Posted By</th>
                          <th>Posted Date</th>
                          <th>Industry</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allJobs?.docs ? (
                          allJobs?.docs.map((value, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{value?.title}</td>
                              <td>{value?.created_by?.name || "N/A"}</td>
                              <td>{formatDate(value?.posted_date)}</td>
                              <td>{value?.industry?.name || "N/A"}</td>
                              <td>
                                <button
                                  onClick={() =>
                                    navigate(`/admin/reviewJob/${value?._id}`)
                                  }
                                  className="unblock-btn"
                                >
                                  View Job Detail
                                </button>
                                <button
                                  onClick={() =>
                                    statusHandler(value?._id, "approved")
                                  }
                                  className="approve-btn"
                                >
                                  Approve
                                </button>
                                <button
                                  onClick={() =>
                                    statusHandler(value?._id, "rejected")
                                  }
                                  className="delete-btn"
                                >
                                  Reject
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7">No Pending Job Requests</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {/* Pagination Controls */}
                    <nav aria-label="Page navigation">
                      <ul className="pagination justify-content-center">
                        {allJobs?.pagination?.prevPage && (
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="#"
                              onClick={() => handlePagination(currentPage - 1)}
                              aria-label="Previous"
                            >
                              <span aria-hidden="true">&laquo;</span>
                            </a>
                          </li>
                        )}
                        {[...Array(allJobs?.pagination?.totalPages)].map(
                          (_, index) => (
                            <li
                              key={index}
                              className={`page-item ${
                                currentPage === index + 1 ? "active" : ""
                              }`}
                            >
                              <a
                                className="page-link"
                                href="#"
                                onClick={() => handlePagination(index + 1)}
                              >
                                {index + 1}
                              </a>
                            </li>
                          )
                        )}
                        {allJobs?.pagination?.nextPage && (
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="#"
                              onClick={() => handlePagination(currentPage + 1)}
                              aria-label="Next"
                            >
                              <span aria-hidden="true">&raquo;</span>
                            </a>
                          </li>
                        )}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproveJobs;
