import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';

const TeamEdit = () => {
  const [team, setTeam] = useState([]);
  const [formData, setFormData] = useState({ name: '', description: '' });
  const [imageFile, setImageFile] = useState(null);  // State for storing the image file
  const [editMode, setEditMode] = useState(false);
  const [currentTeamId, setCurrentTeamId] = useState(null);

// Retrieve the token from localStorage
const token = JSON.parse(localStorage.getItem("token"));

  // Fetch team details
  useEffect(() => {
    const fetchTeam = async () => {
      try {
        const{data} = await axiosInstance.get(`/about-us`);
        setTeam(data?.about?.team);
      } catch (error) {
        console.error('Error fetching team data:', error);
      }
    };

    fetchTeam();
  }, []);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle file input change (for image)
  const handleFileChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleSubmitTeam = async (e) => {
    e.preventDefault();  // Prevent the default form submission
    
    try {
        const formDataToSubmit = new FormData();
        formDataToSubmit.append('name', formData.name);
        formDataToSubmit.append('description', formData.description);

        if (imageFile) {
            formDataToSubmit.append('image', imageFile); // Append image file
        }

         // Setup config with token and content type based on whether we're uploading a file
      const config = {
        headers: {
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
          "Content-Type": imageFile ? "multipart/form-data" : "application/json", // Conditional content-type
        },
      };

        if (editMode) {
            formDataToSubmit.append('teamId', currentTeamId); // Append team ID for update
            await axiosInstance.post('/about-us/team/update', formDataToSubmit,config);
        } else {
            await axiosInstance.post('/about-us/team/add', formDataToSubmit,config);
        }

        // Fetch updated team data
        const response = await axiosInstance.get('/about-us');
        // console.log(response?.data)
        if (response?.data?.about?.team) {
            setTeam(response?.data?.about?.team); // Update the team state
        } else {
            console.error('No team data found in the response:', response.data);
        }

        // Reset form and states
        setFormData({ name: '', description: '' });
        setImageFile(null);
        setEditMode(false);
        setCurrentTeamId(null);
    } catch (error) {
        console.error('Error adding/updating team member:', error.response ? error.response.data : error.message);
    }
};


  // Delete team member
  const handleDelete = async (teamId) => {
    try {
      // Sending the DELETE request without aboutId, passing only teamId in the URL
      const response = await axiosInstance.put(`/about-us/team/update/${teamId}`);

      
      // Assuming the updated team list is returned after deletion, update the state
      setTeam(response?.data?.data?.team);
    } catch (error) {
      console.error('Error deleting team member:', error);
    }
  };
  

  // Edit team member
  const handleEdit = (teamMember) => {
    setFormData({ name: teamMember.name, description: teamMember.description });
    setImageFile(null);  // Clear image for editing (if no new image is selected)
    setEditMode(true);
    setCurrentTeamId(teamMember._id);
  };

  return (
    <div className='mt-3'>
      <h2>Team Management</h2>

      {/* Team Form */}
      {/* <form> */}
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
          className="form-control mb-2"
          // required
        />
        <input
          type="file"
          name="image"
          onChange={handleFileChange}
          className="form-control mb-2"
        />
        <textarea
          name="description"
          placeholder="Description"
          value={formData.description}
          onChange={handleChange}
          className="form-control mb-2"
          // required
        ></textarea>
        <button onClick={handleSubmitTeam} className="btn btn-primary mt-2">{editMode ? 'Update' : 'Add'} Member</button>
        {editMode && <button className='btn btn-danger ms-2 mt-2' type="button" onClick={() => setEditMode(false)}>Cancel Edit</button>}
      {/* </form> */}

      {/* Team List */}
      <div className='mt-3'>
        <h2>Team Members</h2>
        {team?.map((member) => (
          <div key={member._id} style={{ border: '1px solid #ccc', padding: '10px', margin: '10px 0' }}>
            <h3>{member?.name}</h3>
            <img src={member?.image} alt={member.name} style={{ maxWidth: '100px' }} />
            <p>{member?.description}</p>
            <button className='btn btn-info me-2' onClick={() => handleEdit(member)}>Edit</button>
            <button className='btn btn-danger me-2' onClick={() => handleDelete(member._id)}>Delete</button>
          </div>
        ))}
      </div>
      
    </div>
  );
};

export default TeamEdit;
