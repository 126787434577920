import React, { useState, useEffect } from "react";
import "./about.css";
import Member1 from '../../../asset/new/images/member/member1.png';
import Member2 from '../../../asset/new/images/member/member2.png';
import Member3 from '../../../asset/new/images/member/member3.png';
import Member4 from '../../../asset/new/images/member/member4.png';
import { axiosInstance } from "../../../Axios/AxiosInstance";

const Team = () => {
  const [teamData, setTeamData] = useState([]);  // State to hold team data
  const [loading, setLoading] = useState(true);  // Loading state
  const [error, setError] = useState(null);      // Error state

  // Static team members (fallback data)
  const staticTeamData = [
    {
      name: "Mr. Hari Mundra",
      image: Member1,
      description: `Mr. Hari Mundra graduated from IIM Ahmedabad in 1971. He has had 
      an illustrious career spanning 40 years in CXO roles across HUL, RPG, Wockhardt and 
      Essar Oil. He is director on the boards of Ceat Ltd, Tata AutoComp Systems Ltd 
      and Allcargo Logistics Ltd.`,
    },
    {
      name: "Ms. V N Saroja",
      image: Member2,
      description: `IIM Ahmedabad alumnus, Co-founder and former COO of naukri.com 
      and currently Chief Strategy Officer at Indian Agribusiness Systems Private Limited.`,
    },
    {
      name: "CA. Dilip Apte",
      image: Member3,
      description: `CA. Dilip Apte - Former GM-Corporate Relations at HDFC Ltd. 
      and currently with Purnartha Investment Advisors Pune.`,
    },
    {
      name: "Prof. Arvind Sahay",
      image: Member4,
      description: `Prof Arvind Sahay has studied at IIT-Kanpur, IIM Ahmedabad and 
      The University of Texas. He served as Assistant Professor at London Business School 
      (1996-2004). At IIM Ahmedabad, he was Dean (Alumni & External Relations) (2013-16).`,
    },
  ];

  // Fetch team data from API
  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await axiosInstance.get('about-us');  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        // console.log(data.team)
        if (data && data?.team && data?.team?.length > 0) {
          setTeamData(data);  // Use API data if available
        } else {
          // console.log('No data from API, using static data.');
          setTeamData(staticTeamData);  // Fallback to static data
        }
      } catch (err) {
        console.error('Error fetching team data:', err.message); 
        setError('Failed to fetch team data');
        setTeamData(staticTeamData);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamData();
  }, []);

  // Loading state
  if (loading) return <p>Loading team members...</p>;

  // Render team members dynamically or fallback to static data
  return (
    <>
      <h5 className="board">ADVISORY BOARD</h5>
      <div className="row">
        {teamData?.map((member, index) => (
          <div className="col-md-3 col-sm-6" key={index}>
            <div className="our-team">
              <div className="pic">
                <img src={member?.image} alt={member?.name} />
              </div>
              <div className="team-content">
                <h3 className="title">{member?.name}</h3>
                <p className="description">{member?.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Team;
