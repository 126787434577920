import React, { useEffect } from "react";
import { GoLocation } from "react-icons/go";
import { PiBagSimple } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  CandidateStatus,
  GetAllApplicants,
  GetOneJob,
} from "../../Redux/Slices/JobPost";
import { FaIndustry, FaRupeeSign } from "react-icons/fa";
import { FcGraduationCap } from "react-icons/fc";
import { formatTimeAgo } from "../../Helper/FromatTime";
import AdminNavbar from "./Common/AdminNavbar";

const Applicants = () => {
  const { id } = useParams();
  const role = localStorage.getItem("role");
  const dispatch = useDispatch();
  const { jobdetails, jobapplicants } = useSelector((state) => state?.Jobs);

  let applicants = jobapplicants?.filter((item) => item?.type === "applied");

  let shortlistedUser = jobapplicants?.filter(
    (item) => item?.type === "applied" && item?.action_status === "Shortlisted"
  );

  useEffect(() => {
    dispatch(GetOneJob(id));
    dispatch(GetAllApplicants(id));
    document.title = "Job Applicants";
  }, []);

  const handleSeen = async (id, jobid,action) => {
    if (
      role === "Employer" &&
      !["Viewed", "Downloaded", "Shortlisted", "Not Suitable"].includes(action)
    ) {
      let data = {
        user_id:id,
        job_id:jobid,
        action: "Viewed",
      };
      await CandidateStatus(data);
    }
  };

  return (
    <>
      {role === "Admin" && <AdminNavbar />}
      <div className="applicant-profile-container">
        <h2> Applicants</h2>
        {/* Job Description */}
        <h5 className="">{jobdetails?.title}</h5>
        <p className="">{jobdetails?.company_name}</p>
        <div className="joblist-car m-2">
          <div className="job-details my-2 ms-2">
          {jobdetails?.exp?.from !== null &&
                jobdetails?.exp?.from !== undefined &&
                jobdetails?.exp?.to !== null &&
                jobdetails?.exp?.to !== undefined && (
                  <span>
                    <PiBagSimple style={{ fontSize: "19px" }} />{" "}
                    {jobdetails.exp.from} - {jobdetails.exp.to} Years
                  </span>
                )}
              {jobdetails?.ctc?.from_lac !== null &&
                jobdetails?.ctc?.from_lac !== undefined &&
                jobdetails?.ctc?.from_thousand !== null &&
                jobdetails?.ctc?.from_thousand !== undefined &&
                jobdetails?.ctc?.to_lac !== null &&
                jobdetails?.ctc?.to_lac !== undefined &&
                jobdetails?.ctc?.to_thousand !== null &&
                jobdetails?.ctc?.to_thousand !== undefined && (
                  <span>
                    <FaRupeeSign style={{ fontSize: "19px" }} />{" "}
                    {jobdetails.ctc.from_lac}.{jobdetails.ctc.from_thousand}{" "}
                    Lacs - {jobdetails.ctc.to_lac}.{jobdetails.ctc.to_thousand}{" "}
                    Lacs
                  </span>
                )}
            <span>
              <GoLocation style={{ fontSize: "19px" }} />{" "}
              {jobdetails?.cities[0]}
            </span>
            <span>
              <FaIndustry style={{ fontSize: "19px" }} />{" "}
              {jobdetails?.industry?.name}
            </span>
            <span>
              <FcGraduationCap style={{ fontSize: "19px" }} /> Charted Account
            </span>
          </div>

          <div className="card-buttom d-flex flex-row-reverse">
            <button className="btn btn-primary card-buttom2">
              {formatTimeAgo(new Date(jobdetails?.admin_approval_date))}
            </button>
            <p className="job-type">Job Type : {jobdetails?.category}</p>
          </div>
        </div>

        {/* Post Analytics */}
        <div className="d-flex justify-content-around ">
          <div className="p-1 border border-1 das-card2 mt-3">
            <p className="fs-6 ">Applicants</p>
            <center>
              <h1 className=" font-size-no">
                {jobdetails?.applications?.length}
              </h1>
            </center>
          </div>
          <div className="p-1 border border-1 das-card2 mt-3">
            <p className="fs-6 ">Views</p>
            <center>
              <h1 className=" font-size-no">{jobdetails?.views}</h1>
            </center>
          </div>
          <div className="p-1 border border-1 das-card2 mt-3">
            <p className="fs-6 ">Shortlisted</p>
            <center>
              <h1 className=" font-size-no">{shortlistedUser?.length || 0}</h1>
            </center>
          </div>
        </div>

        {/* Table */}

        <div
          style={{ backgroundColor: "white", padding: "14px" }}
          className="table-responsive px-2"
        >
          <div id="example_wrapper" className="dataTables_wrapper no-footer">
            <div className="dataTables_length" id="example_length">
              <label>
                Show{" "}
                <select name="example_length" aria-controls="example" className>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>{" "}
                entries
              </label>
              <input
                className="search-table"
                type="search"
                placeholder="Search"
                aria-label="Search"
              ></input>
            </div>
            <br />
            <table
              id="example"
              className="table dataTable no-footer"
              cellSpacing={0}
              style={{ width: "100%" }}
              aria-describedby="example_info"
            >
              <thead className="table-headline">
                <tr>
                  <th>Sno</th>
                  <th>Applicant Name</th>
                  <th>Applied On</th>
                  <th>Experience</th>
                  <th>Resume/Update Status</th>
                  <th>Status</th>
                </tr>
              </thead>
              {/* Add rows for approved jobs here */}

              <tbody>
                {applicants?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.user_id?.name}</td>
                    <td>{formatTimeAgo(new Date(item?.created_at))}</td>
                    <td>
                      {item?.user_id?.total_experience?.year}{" "}
                      {item?.user_id?.total_experience?.month
                        ? `.${item?.user_id?.total_experience?.month}`
                        : ""}{" "}
                      Year
                    </td>
                    <td>
                      <Link
                        onClick={() =>
                          handleSeen(item?.user_id?._id,item?.job_id, item?.action_status)
                        }
                        to={
                          role === "Employer"
                            ? `/employer/applicant-profile/${item?.user_id?._id}/jobid/${item?.job_id
                            }`
                            : `/admin/applicant-profile/${item?.user_id?._id}/jobid/${item?.job_id
                            }`
                        }
                      >
                        View Resume/ Update Status
                      </Link>
                    </td>
                    <td>{item?.action_status || "NA"}</td>
                  </tr>
                ))}
                {applicants?.length === 0 && (
                  <td valign="top" colspan="6" className="dataTables_empty p-2">
                    {" "}
                    <center>No data available in table</center>
                  </td>
                )}
              </tbody>
            </table>
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default Applicants;
